// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAJzMx3q6gFqc4giZ41Ux0mFjpwedPsboU",
    authDomain: "panel-3adimension.firebaseapp.com",
    databaseURL: "https://panel-3adimension.firebaseio.com",
    projectId: "panel-3adimension",
    storageBucket: "panel-3adimension.appspot.com",
    messagingSenderId: "827261047170",
    appId: "1:827261047170:web:be560c1945a2bc0dc03f9b",
    measurementId: "G-4LBNVL0JLR"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
