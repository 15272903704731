import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/*Firebase */
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/modules/material.module';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconService } from './shared/services/svgicon.service';
import { AlertasService } from './shared/services/alertas.service';
import { FormulariosService } from './shared/services/formularios.service';
import { DataBaseService } from './shared/services/database.service';





@NgModule({
  declarations: [
    AppComponent,

    
   
   

    
   

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatIconModule,

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,

    AppRoutingModule,

    BrowserAnimationsModule,

    MaterialModule,

    


    
  ],
  providers: [{ provide: BUCKET, useValue: 'gs://panel-3adimension.appspot.com' }, SvgIconService, AlertasService, FormulariosService, DataBaseService],
  bootstrap: [AppComponent]
})
export class AppModule { }
