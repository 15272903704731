import { Injectable } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl, AbstractControl } from '@angular/forms';


@Injectable()

export class FormulariosService {
  constructor(

  ) { }

  public compareObjectId(local: any, db: any) {
    if (local.id == db.id)
      return true;
    else return false
  }

  public compareObjectIdLeft(local: any, db: any) {
    if (local.id == db)
      return true;
    else return false
  }

  public compareObjectIndice(local: any, db: any) {
    if (local.indice == db.indice)
      return true;
    else return false
  }

  public compareObjectIndiceLeft(local: any, db: any) {
    if (local.indice == db)
      return true;
    else return false
  }



  public resetForm(form:any) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }
  public addChipEmail(event: MatChipInputEvent, Matriz: string[], FormControl?: AbstractControl): boolean {
    const input = event.input;
    const value = event.value;
    const caract = new RegExp(/^(([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+\,?)+$/);
    if (value && caract.test(value) == false) { return true; } else {
      if ((value || '').trim()) {
        value.split(',').forEach(e => {          Matriz.push(e.trim().toLowerCase());        })
      }
      if (input) { input.value = ''; }
      if (FormControl) FormControl.setValue(Matriz);
      return false;
    }
  }

  public addChipNumber(event: MatChipInputEvent, Matriz: string[], FormControl?: AbstractControl): boolean {
    const input = event.input;
    const value = event.value;
    const caract = new RegExp(/^((([0-9])+\,?)+\s?)+$/);
    if (value && caract.test(value) == false) { return true; } else {
      if ((value || '').trim()) {
        value.split(',').forEach(e => {          Matriz.push(e.trim().toLowerCase());        })
      }
      if (input) { input.value = ''; }
      if (FormControl) FormControl.setValue(Matriz);
      return false;
    }
  }

  public addChipString(event: MatChipInputEvent, Matriz: string[], FormControl?: AbstractControl): void {
    //console.log(event)
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      let v = value.split(',').forEach(e => { Matriz.push(e.trim().toLowerCase());})
    } // Add
    if (input) { input.value = ''; }// Reset the input value
    if (FormControl)    FormControl.setValue(Matriz);
  }

  public removeChipString(item: string, Matriz: string[], FormControl?: AbstractControl): void {
    const index = Matriz.indexOf(item);
    if (index >= 0) { Matriz.splice(index, 1); }
    if (FormControl)  FormControl.setValue(Matriz);
  }
}





