import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';





@Injectable()

export class AlertasService {
  constructor(
    private snackBar: MatSnackBar,
  ) { }

  public alertaSuccess(operacion:string,) {
    this.snackBar.open(operacion, 'x', {
      duration: 6000, horizontalPosition: 'end', verticalPosition: 'top', panelClass: ['alert-success', 'mt-5']
    });
  }

  public alertaDanger(operacion: string, ) {
    this.snackBar.open(operacion, 'x', {
      duration: 6000, horizontalPosition: 'end', verticalPosition: 'top', panelClass: ['alert-danger', 'mt-5']
    });
  }

  public alertaWarning(operacion: string, ) {
    this.snackBar.open(operacion, 'x', {
      duration: 6000, horizontalPosition: 'end', verticalPosition: 'top', panelClass: ['alert-warning', 'mt-5']
    });
  }


  init() {
    
  }
}









