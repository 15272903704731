import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';




@Injectable()

export class SvgIconService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) { }
  init() {
    this.iconRegistry.addSvgIcon('regla-h', this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/svg/regla-h.svg'));
    this.iconRegistry.addSvgIcon('regla-v', this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/svg/regla-v.svg'));
    this.iconRegistry.addSvgIcon('regla-d', this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/svg/regla-d.svg'));
    this.iconRegistry.addSvgIcon('pesa', this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/svg/pesa.svg'));
  }
}










